import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";

function ColumnHeader({
  // Props
  id,
  display,
  justify = "left",
  sortField,
  reverseSort,
  onClick,
}) {
  const handleClick = React.useCallback(() => onClick(id), [id, onClick]);

  const order = React.useMemo(() => {
    if (sortField === id) {
      return reverseSort ? "desc" : "asc";
    }
    return "asc";
  }, [id, reverseSort, sortField]);

  return (
    <Grid container justifyContent={justify}>
      <Grid item xs="auto" alignItems="center">
        <TableSortLabel
          active={sortField === id}
          direction={order}
          onClick={handleClick}
          data-cy={`${id}-column-header`}
        >
          <Typography variant="body2">{display}</Typography>
        </TableSortLabel>
      </Grid>
    </Grid>
  );
}

ColumnHeader.propTypes = {
  id: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  justify: PropTypes.string,
  sortField: PropTypes.string,
  reverseSort: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.memo(ColumnHeader);
