/* eslint-disable max-classes-per-file */
/* eslint-env browser */
import {DateTime} from "luxon";

class Notification {
  constructor($injector, notification, study) {
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
    this._backendConfig = $injector.get("backendConfig");
    this._Session = $injector.get("Session");
    this.features = $injector.get("Config").features;
    Object.assign(this, notification);
    this.type = notification.type || notification.title;

    if (!this.tzSerial) {
      if (this.type !== "Unconfigured Device On Patient" && study) {
        this.studyId = study.id;
        this.studyStartDate = study.studyStartDate;
        this.studyEndDate = study.studyEndDate;
        this.studyIndication = study.studyIndication;
        this.studyNotes = study.studyNotes;
        this.studyState = study.studyState;
        this.studyType = study.studyType;
      }
    }

    // Valid timestamps are 2010 or later
    this.validTimestamp = DateTime.fromISO(this.timestamp).year >= 2010;
  }

  get locked() {
    // Item is locked if there is a lockedAt timestamp
    let locked = !!this.lockedAt;
    // If the item is locked by you, display it as unlocked.
    if (
      locked &&
      this.lockedBy === this._Authentication.getUserId() &&
      (this.sessionId === null || this.sessionId === this._Session.sessionId)
    ) {
      locked = false;
    }
    return locked;
  }

  get assignedToOtherUser() {
    if (!this.features.itemAssignments || this.assignedUsers?.length === 0) {
      return false;
    }

    const currentUserId = this._Authentication.getUserId();
    const currentUserIsAssigned = this.assignedUserIds.includes(currentUserId);

    return !currentUserIsAssigned;
  }

  get isUnopenable() {
    return this.locked || this.assignedToOtherUser;
  }

  get assignedUserIds() {
    return this.assignedUsers?.map((user) => user.userId) || [];
  }

  get assignedUserNames() {
    return this.assignedUsers?.map((user) => user.userFullName) || [];
  }

  async lock() {
    const {sessionId} = this._Session;
    const {data: lockedItem} = await this._httpGet(`/notifications/lock/${this.id}?sessionId=${sessionId}`);

    return Object.assign(this, lockedItem);
  }

  /*
   * @see SRS: BR-1554
   */
  unlock() {
    const userId = this._Authentication.getUserId();
    const {sessionId} = this._Session;
    const urlWithQuery = `/notifications/lock/${this.id}?userId=${userId}&sessionId=${sessionId}`;
    return this._httpDelete(urlWithQuery);
  }

  /*
   * @see SRS: BR-1246
   */
  httpPatch(url, data) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.patch(`${baseUrl}${url}`, data, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpGet(url, params = {}) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }

  _httpDelete(url) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.delete(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}

/*
 * @see SRS: BR-2139
 */
class UnconfiguredDeviceNotification extends Notification {}

class StudyActionsFailedNotification extends Notification {
  constructor($injector, studyActionsFailedNotification, study) {
    try {
      studyActionsFailedNotification.details = JSON.parse(studyActionsFailedNotification.details);
    } catch (e) {
      /* Already parsed */
    }
    super($injector, studyActionsFailedNotification, study);
  }

  /*
   * @see SRS: BR-1246
   */
  complete() {
    return this.httpPatch(`/notifications/${this.id}`, {completed: true}).then((response) => {
      if (response.status === 200) {
        this.completed = true;
      } else {
        console.error("Notification could not be completed");
      }
    });
  }
}

class LeadDisconnectedNotification extends Notification {
  constructor($injector, leadDisconnectedNotification, study) {
    try {
      leadDisconnectedNotification.details = JSON.parse(leadDisconnectedNotification.details);
    } catch (e) {
      /* Already parsed */
    }
    super($injector, leadDisconnectedNotification, study);
  }

  /*
   * @see SRS: BR-1246
   */
  complete() {
    return this.httpPatch(`/notifications/${this.id}`, {completed: true}).then((response) => {
      if (response.status === 200) {
        this.completed = true;
      } else {
        console.error("Notification could not be completed");
      }
    });
  }
}

class PublishedReportRejectedNotification extends Notification {
  constructor($injector, publishedReportRejectedNotification, study) {
    try {
      publishedReportRejectedNotification.details = JSON.parse(publishedReportRejectedNotification.details);
    } catch (e) {
      /* Already parsed */
    }
    super($injector, publishedReportRejectedNotification, study);
  }

  complete() {
    return this.httpPatch(`/notifications/${this.id}`, {completed: true}).then((response) => {
      if (response.status === 200) {
        this.completed = true;
      } else {
        console.error("Notification could not be completed");
      }
    });
  }
}

/* @ngInject */
export default class NotificationService {
  constructor($injector) {
    this._$injector = $injector;
    this._backendConfig = $injector.get("backendConfig");
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
    this._features = this._backendConfig.features;
    this._supportedNotificationTypes = [
      "Unconfigured Device On Patient",
      "Study Action Failed",
      "Lead Disconnected",
      "Published Report Rejected",
    ];
  }

  /**
   * Makes request for notifications
   * @param {Object} params parameters to limit search by
   *
   * @see SRS: BR-2139
   * @returns {Promise} Promise object represents the result of get http request
   */
  getNotifications(params = {}) {
    const url = "/notifications";
    return this._httpGet(url, params);
  }

  /**
   * Gets names of supported notification types
   *
   * @see SRS: BR-2139
   * @returns {Array} Notification types
   */
  getNotificationTypes() {
    return this._supportedNotificationTypes;
  }

  getUnsupportedNotificationTypes() {
    const unsupportedTypes = [];
    return unsupportedTypes;
  }

  /**
   * Creates notification class object from the given notification
   * @param {Object} notification
   * @param {Object} study
   *
   * @see SRS: BR-2139
   * @returns {Notification} notification class object
   */
  instantiateNotification(notification, study) {
    let notificationInstance;

    switch (notification.title) {
      case "Unconfigured Device On Patient":
        notificationInstance = new UnconfiguredDeviceNotification(this._$injector, notification);
        break;
      case "Study Action Failed":
        notificationInstance = new StudyActionsFailedNotification(this._$injector, notification, study);
        break;
      case "Lead Disconnected":
        notificationInstance = new LeadDisconnectedNotification(this._$injector, notification, study);
        break;
      case "Published Report Rejected":
        notificationInstance = new PublishedReportRejectedNotification(this._$injector, notification, study);
        break;
      default:
        throw new Error("Notification could not be created, missing or unsupported notification type");
    }
    return notificationInstance;
  }

  /*
   * @see SRS: BR-2139
   */
  _httpGet(url, params) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }

  /*
   * @see SRS: BR-2139
   */
  _httpPatch(url, data) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.patch(`${baseUrl}${url}`, data, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
