/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import FilterAlt from "@mui/icons-material/FilterAlt";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Badge from "@mui/material/Badge";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import IconButtonWithTooltip from "./IconButtonWithTooltip.jsx";

function StatusFilter({statuses, statusFilter}) {
  //---------------------------------------------------------------------------
  // Menu state management
  //---------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const invisible = React.useMemo(() => {
    // Don't show badge if all of the filters are selected
    const numSelected = Object.values(statusFilter).filter((selected) => selected).length;
    return numSelected === Object.values(statusFilter).length;
  }, [statusFilter]);

  return (
    <>
      <IconButtonWithTooltip
        title="Filter Status"
        onClick={handleOpenMenu}
        color="tertiary"
        otherProps={{size: "small"}}
        data-cy="filter-status-button"
      >
        <Badge variant="dot" color="secondary" invisible={invisible}>
          <FilterAlt />
        </Badge>
      </IconButtonWithTooltip>

      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        <MenuList dense data-cy="filter-status-menu">
          {statuses.map(({id, label, handleClick}) => (
            <MenuItem onClick={handleClick} key={id} data-cy={`filter-${id}-button`}>
              <Checkbox sx={{py: 0, pl: 0, pr: 1}} size="small" checked={statusFilter[id]} />
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}

StatusFilter.propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      handleClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  statusFilter: PropTypes.object.isRequired,
};

export default StatusFilter;
