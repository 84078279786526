import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

function TableLoading() {
  return (
    <Grid container>
      <Grid item sx={{mt: 10}} xs={12} align="center">
        <CircularProgress color="secondary" size={100} data-cy="loading-circle" />
      </Grid>
    </Grid>
  );
}

export default TableLoading;
