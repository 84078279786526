import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

function Pagination({pageSize, count, page, setPage}) {
  const start = page * pageSize + 1;
  const end = Math.min((page + 1) * pageSize, count);
  const maxPage = Math.ceil(count / pageSize) - 1;

  const setFirstPage = () => setPage(0);
  const setPrevPage = () => setPage((previous) => previous - 1);
  const setNextPage = () => setPage((previous) => previous + 1);
  const setLastPage = () => setPage(maxPage);

  return (
    <Box>
      <Grid container sx={{p: 1.5}} style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
        <Grid item xs={4}>
          <IconButton color="secondary" onClick={setFirstPage} disabled={page <= 0}>
            <FirstPage />
          </IconButton>
          <IconButton color="secondary" onClick={setPrevPage} disabled={page <= 0}>
            <ChevronLeft />
          </IconButton>
        </Grid>
        <Grid item xs={4} align="center">
          <span className="displayedRange">
            Showing {start} - {end} of {count}
          </span>
        </Grid>
        <Grid item xs={4} align="right">
          <IconButton color="secondary" onClick={setNextPage} disabled={page >= maxPage}>
            <ChevronRight />
          </IconButton>
          <IconButton color="secondary" onClick={setLastPage} disabled={page >= maxPage}>
            <LastPage />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default React.memo(Pagination);
