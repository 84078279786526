import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Typography from "@mui/material/Typography";

function NoResults() {
  return (
    <Typography textAlign="center" variant="h5" paddingTop="100px" data-cy="no-results-message">
      No Results
    </Typography>
  );
}

export default React.memo(NoResults);
