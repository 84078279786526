/* eslint-disable max-classes-per-file */
/* eslint-env browser */

class MessageOption {
  constructor($injector, messageOption) {
    Object.assign(this, messageOption);
  }
}

/* @ngInject */
export default class MessageOptionsService {
  constructor($injector, backendConfig, $http, Authentication) {
    this._$injector = $injector;
    this._backendConfig = backendConfig;
    this._$http = $http;
    this._Authentication = Authentication;
  }

  /**
   * Gets all message options
   * @param {Object} params
   * @returns {Promise.<Object[], Object>} Promise that will be resolved with array of
   *                                          device message option objects or rejected
   *                                          with response object
   */
  getAll(params) {
    const url = "/deviceMessageOptions";
    return this._httpGet(url, params).then((response) => {
      return response.data.map((messageOption) => new MessageOption(this._$injector, messageOption));
    });
  }

  _httpGet(url, params) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }
}
