import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip from "@mui/material/Tooltip";

function UncontrolledRadioInput({
  // Props
  value,
  setValue,
  name,
  children,
  options,
  id,
  disabledOption = () => false,
  row = true,
  ratio = [6, 6],
  tooltips = [],
}) {
  const [controlledValue, setControlledValue] = React.useState(value);

  const handleChange = (event) => {
    setControlledValue(event.target.value);
    setValue(name, event.target.value);
  };

  React.useEffect(() => {
    setValue(name, value);
  }, [name, setValue, value]);

  return (
    <Grid container alignItems="center" data-cy={id}>
      <Grid item xs={row ? ratio[0] : 12}>
        <Grid container alignItems="center">
          {children}
        </Grid>
      </Grid>
      <Grid item xs={row ? ratio[1] : 12}>
        <RadioGroup row={row} name={name} value={controlledValue} onChange={handleChange}>
          {options.map((option, index) => {
            const optionId = typeof option === "string" ? option : option.id;
            const optionName = typeof option === "string" ? option : option.name;

            return (
              <FormControlLabel
                id={`${id}-${index}`}
                disabled={disabledOption(option)}
                key={`${id}-${optionId}`}
                value={optionId}
                label={optionName}
                control={
                  <Tooltip title={tooltips[index]} arrow>
                    <Radio size="small" />
                  </Tooltip>
                }
              />
            );
          })}
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

UncontrolledRadioInput.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  options: PropTypes.array.isRequired,
  id: PropTypes.string,
  disabledOption: PropTypes.func,
  row: PropTypes.bool,
  ratio: PropTypes.array,
  tooltips: PropTypes.array,
};

export default UncontrolledRadioInput;
